import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/notifications')

/**
 * @api GET /api/notifications/{groupId}/families/companies
 *
 * @param {String} groupId
 * @param {Object} params
 * @param {Object} cancelToken
 */
const listCompanies = (groupId, params, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel('Request cancelled')
	}
	const newCancelToken = Configurator.CancelToken.source()
	const url = `${groupId}/families/companies`
	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			params: params
		})
	}
}

/**
 * @api GET /api/notifications/{groupId}/families
 *
 * @param {String} groupId
 */
const listFamilies = groupId => {
	const url = `${groupId}/families`
	return axios.get(url)
}

/**
 * @api GET /api/notifications/frequencies
 */
const listFrequencies = () => {
	const url = 'frequencies'
	return axios.get(url)
}

/**
 * @api GET /api/notifications/frequencies
 */
const listAuthors = () => {
	const url = 'notification-authors'
	return axios.get(url)
}

/**
 * @api PATCH /api/notifications/{groupId}/families/companies/{vendorId}
 *
 * @param {String} groupId
 * @param {Number} vendorId
 * @param {Object} data
 */
const updateCompanyFamilies = (groupId, vendorId, data) => {
	const url = `${groupId}/families/companies/${vendorId}`
	return axios.patch(url, data)
}

/**
 * @api PATCH /api/notifications/{groupId}/families/{familyId}
 *
 * @param {String} groupId
 * @param {Number} familyId
 * @param {Object} data
 */
const updateFamily = (groupId, familyId, data) => {
	const url = `${groupId}/families/${familyId}`
	return axios.patch(url, data)
}

/**
 * @api PATCH /api/notifications/{groupId}/families/{familyId}/companies/{vendorId}
 *
 * @param {String} groupId
 * @param {Number} familyId
 * @param {Number} vendorId
 * @param {Object} data
 */
const updateFamilyOfCompany = (groupId, familyId, vendorId, data) => {
	const url = `${groupId}/families/${familyId}/companies/${vendorId}`
	return axios.patch(url, data)
}

/**
 * @api PATCH /api/notifications/{groupId}/families/{familyId}/types
 *
 * @param {String} groupId
 * @param {Number} familyId
 * @param {Object} data
 */
const updateUserPreferences = (groupId, familyId, data) => {
	const url = `${groupId}/families/${familyId}/types`
	return axios.patch(url, data)
}

export default {
	listCompanies: listCompanies,

	listFamilies: listFamilies,

	listFrequencies: listFrequencies,
	listAuthors: listAuthors,

	updateCompanyFamilies: updateCompanyFamilies,
	updateFamily: updateFamily,
	updateFamilyOfCompany: updateFamilyOfCompany,
	updateUserPreferences: updateUserPreferences
}
