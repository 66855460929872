<template>
	<w-layout column fill-height>
		<w-flex shrink>
			<NotificationsHeader />
		</w-flex>
		<w-flex scroll-y>
			<BasicSettings />
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'

import NotificationsModuleGuard from '@/mixins/ModulesGuards/Notifications/NotificationsModuleGuard'

export default {
	name: 'NotificationsSettings',
	components: {
		BasicSettings: () => ({
			component: import('@/components/Notifications/Profile/BasicSettings')
		}),
		NotificationsHeader: () => ({
			component: import('@/components/Notifications/Profile/NotificationsHeader')
		})
	},
	mixins: [NotificationsModuleGuard],
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm?.selected
		})
	},
	created: function () {
		this.service.init()
	}
}
</script>
