// ============================================================================
// NotificationsService
// --------------
// Notifications module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/NotificationsApi'
import { Bus as BUS, Events as EVENTS } from '@/events/Notifications/NotificationsEvents'

// Internals
// ---------
const Private = {
	listFamilies: function () {
		const groupId = window.location.hostname
		return API.listFamilies(groupId)
			.then(res => res.data.data)
			.then(families => {
				families.forEach(family => {
					family.types.forEach(type => {
						let sources = []
						type.sources.forEach(source => {
							sources.push({
								id: source.id,
								text: Private.formatAuthorTitle(source.name)
							})
						})
						type.sources = sources
					})
				})
				return store.dispatch('notifications/setFamilies', families)
			})
	},
	listFrequencies: function () {
		return API.listFrequencies()
			.then(res => res.data.data)
			.then(frequencies =>
				frequencies.map(frequency => ({
					text: window.vueInstance.$t("notifications.frequencies." + frequency.key),
					value: frequency.key
				}))
			)
			.then(frequencies => {
				store.dispatch('notifications/setFrequencies', frequencies)
				return frequencies
			})
	},
	listAuthors: function () {
		return API.listAuthors()
			.then(res => res.data.data)
			.then(sources =>
				sources.map(source => ({
					id: source.id,
					text: Private.formatAuthorTitle(source.name)
				}))
			)
			.then(sources => {
				store.dispatch('notifications/setSources', sources)
				return sources
			})
	},
	formatCompanyTitle: function (company) {
		if ((store.state.auth?.isAccountant ?? false) && company.client_code) {
			company.name = company.name + ' (' + company.client_code + ')'
		}
	},
	formatAuthorTitle: function (authorName) {
		if (store.state.auth?.isAccountant) {
			return window.vueInstance.$t('notifications.profile.accountant_member.' + authorName)
		} else {
			return window.vueInstance.$t('notifications.profile.client_member.' + authorName)
		}
	}
}

// -------
// Exports
// -------
export default {
	init: function () {
		Private.listFamilies()
		if (store.state.notifications.frequencies.length === 0) {
			Private.listFrequencies()
			Private.listAuthors()
		}
	},
	listCompanies: function (paginationData, searchedText = null) {
		let params = {
			page: paginationData.page,
			po: paginationData.rowsPerPage
		}
		if (searchedText) {
			params.q = searchedText
		}
		const groupId = window.location.hostname
		const callAnswer = API.listCompanies(groupId, params, store.state.notifications.cancelTokens['listCompanies'])
		store.dispatch('notifications/setCancelToken', { cancelToken: callAnswer.cancelToken, item: 'listCompanies' })
		return callAnswer.promise
			.then(res => res.data.data)
			.then(data => {
				if (store.state.auth?.isAccountant ?? false) {
					data.data = data.data.map(company => {
						if (company.client_code) {
							company.name = company.name + ' (' + company.client_code + ')'
						}
						return company
					})
				}
				return data
			})
	},
	toggleCompanyFamilies: function (vendorId, toggleValue) {
		const groupId = window.location.hostname
		const data = {
			is_active: toggleValue
		}
		return API.updateCompanyFamilies(groupId, vendorId, data)
			.then(res => res.data.data)
			.then(updatedData => {
				store.dispatch('notifications/setFamilies', updatedData.families)
				return updatedData.company
			})
			.then(company => {
				Private.formatCompanyTitle(company)
				return company
			})
	},
	toggleFamily: function (familyId, toggleValue) {
		const groupId = window.location.hostname
		const data = {
			is_active: toggleValue
		}
		return API.updateFamily(groupId, familyId, data)
			.then(res => res.data.data)
			.then(family => {
				store.dispatch('notifications/updateFamily', family)
				BUS.emit(EVENTS.FAMILY_UPDATED, family)
				return family
			})
	},
	toggleFamilyOfCompany: function (familyId, vendorId, toggleValue) {
		const groupId = window.location.hostname
		const data = {
			is_active: toggleValue
		}
		return API.updateFamilyOfCompany(groupId, familyId, vendorId, data)
			.then(res => res.data.data)
			.then(updatedData => {
				store.dispatch('notifications/updateFamily', updatedData.family)
				return updatedData.company
			})
			.then(company => {
				Private.formatCompanyTitle(company)
				return company
			})
	},
	updateUserPreferences: function (familyId, types) {
		const groupId = window.location.hostname
		const data = {
			types: types
		}
		return API.updateUserPreferences(groupId, familyId, data).then(res => res.data.data)
	}
}
